

export const Sex = (val) => {
    let sex = '';
    switch (val) {
        case 'F':
            sex += 'Female';
        break;
        case 'M':
            sex += 'Male';
        break;
    }
    return sex;
};

export const Education = (val) => {
    let ed = '';
    switch (val) {
        case 'OPS':
            ed += 'Post school  diploma or Cert 3 or 4';
            break;
        case 'Deg':
            ed += 'Bachelor Degree, or higher';
            break;
        case 'CHS':
            ed += 'Completed High School';
            break;
        case 'NCHS':
            ed += 'Did not complete High School';
            break;
        case 'PG':
            ed += 'Graduate Certificate, Graduate Diploma, Masters, Doctorate';
            break;
        
    }
    return ed;
};


export const Household = (val) => {
    let hh = '';
    switch (val) {
        case 'Coup':
            hh += 'Couple';
            break;
        case 'Coupdep':
            hh += 'Couple with dependent children';
            break;
        case 'Sparent':
            hh += 'Single Parent with dependent children';
            break;
        case 'Other':
            hh += 'Other';
            break;
    }
    return hh;
}