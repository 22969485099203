import React, { Component } from "react";
import editsvg, { ReactComponent as Edit } from  "../../images/Icons/edit.svg"
import  { Sex, Education, Household } from "../actions/get-selected-value"
class UserSubmittedValues extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
      
    }
    
    handleClick() {
        return this.props.onClick;
    }

    render() {
        return (
            <div className={"results-user cell small-6 medium-12 animated pulse " + (this.props.delay)} id={this.props.user}>
                { 
                    
                    (this.props.none && this.props.user === undefined)
                    ?
                        
                        <div>
                            <a class="results-user__none" onClick={this.handleClick()}>
                                <span>+</span>
                                <span>Add another profile to compare data</span>
                            </a>
                        </div>
                        
                    :
                        <>
                        <div className="grid-x grid-margin-x results-user__info">
                            <div className="cell medium-2 medium-offset-1">
                                <h6 className="data-field">Age</h6>
                                <span className="data-value">{this.props.age_group}</span>
                            </div>
                            <div className="cell medium-2">
                                <h6 className="data-field">Sex</h6>
                                <span className="data-value">{Sex(this.props.sex)}</span>
                            </div>
                            <div className="cell medium-3">
                                <h6 className="data-field">Education Level</h6>
                                    <span className="data-value">{Education(this.props.education)}</span>
                            </div>
                            <div className="cell medium-3">
                                <h6 className="data-field">Household</h6>
                                    <span className="data-value">{Household(this.props.household_type)}</span>
                            </div>
                        </div>
                        <div className="edit-primary-user">
                            <a onClick={this.handleClick()}>
                                <span className="edit-button">
                                    <img src={editsvg} alt="Edit"/>
                                    <span>Edit</span>
                                </span>
                            </a>
                        </div>
                    </>
                }
            </div>
            
        )
    }
}

export default UserSubmittedValues