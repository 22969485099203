import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UserForm from "../components/userforms/userform-modal"
import ResultTabs from "../components/resulttabs/result-tablinks"
import ResultTabContents from '../components/resulttabs/result-tabcontents'
import AOS from 'aos'
import Logo from "../components/homepage/logo";
import Footer from "../components/footer";

class Results extends Component {
  componentDidMount() {
    AOS.init({
      duration: 500,
    })
  }

 

  render() {
    
    return (
      <Layout>
        <SEO title="Your Results" />
        <div className="grid-container narrow" id="results-section">
          <section className="results-section">
            <div className="results-section__content text-center">
             <Logo title="Your Results" />
              <div className="typography narrow-width">
                <p>Based on the information you entered, here is some data from the HILDA survey showing how others with similar
                characteristics are faring.</p>
              </div>
            </div>
            <div className="results-section__user grid-x grid-margin-x">
              <UserForm />
            </div>
          </section>
          <section className="results-tabs">
            <div className="results-tabs__wrapper">
              <ResultTabs />
            </div>
          </section>
        </div>

        <ResultTabContents />
        <Footer />
      </Layout>
    )
  } 
}

export default Results
