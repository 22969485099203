import data from '../../data/hilda-final.json';

const GetUser = (user) => {
    if (typeof window !== 'undefined' && window.sessionStorage.getItem(user)) {
        let get_user = window.sessionStorage.getItem(user);
        const id = JSON.parse(get_user);
        const response = data[data.map(function (item) { return item.id; }).indexOf(id)];
        return response ? response : false;
    }
    
};

export default GetUser