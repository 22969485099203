import React, { Component } from "react"
import BasicData from "../other/basic-data"
import GetUser from "../actions/get-user";

class FinanceResultTabContent extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            user1: false,
            user2: false,
        }
    }

    componentWillMount() {
        if (typeof window === 'undefined') {
            return;
          }
        const user_1 = GetUser('user-1');
        const user_2 = GetUser('user-2');
        this.setState({ user1: user_1, user2: user_2 });
    }

    toLocale = (data) => {
        if(data == null) {
            return false;
        }

        if(data !== null || data !== 'undefined') {
            return data.toLocaleString('AUD', { maximumFractionDigits: 2 });
        }
        
    }

    render() {
        return (
            <div className="grid-container">
                <div className="typography-fields white">
                    <h1 className="text-shadow fwbold">Finance</h1>
                    <p>This section focuses on individual financial well-being.</p>
                </div>
                <BasicData 
                    name="annual-wage"
                    color="white"
                    datatype="currency-data"
                    background="coin-background"
                    title="Annual Wage" 
                    description="This figure reflects the mean annual wage after tax for an individual similar to you in the survey." 
                    user1={this.state.user1 ? this.state.user1.finance_annualwage : false}
                    user2={this.state.user2 ? this.state.user2.finance_annualwage : false}
                    tooltip_message="Mean weekly earnings of full-time employees have increased by almost 21% for men and 24% for women."
                />
                <BasicData
                    name="money-in-the-bank"
                    title="Money in bank account"
                    color="white"
                    datatype="currency-data"
                    background="coin-background"
                    description="The figure shown is the average amount of money an individual like you has in their bank account."
                    user1={this.state.user1 ? this.state.user1.finance_bankmoney : false}
                    user2={this.state.user2 ? this.state.user2.finance_bankmoney : false}
                    tooltip_message="Savings tend to increase as financial literacy increases. The economic situation of people with low financial literacy, may dictate a focus on the short term. For example, uncertain income may require individuals to only think about how to pay the next month's rent or bills."
                />
                <BasicData
                    name="household-debt"
                    title="Household Debt"
                    color="white"
                    datatype="currency-data"
                    background="coin-background"
                    description="This refers to the combined debt of all people in a household at the time of the survey."
                    user1={this.state.user1 ? this.state.user1.finance_household_debt : false}
                    user2={this.state.user2 ? this.state.user2.finance_household_debt : false}
                    tooltip_message="Single parent households generally have the highest levels of household debt."
                />
                <BasicData
                    name="montyly-rent"
                    title="Monthly rent/Mortgage"
                    color="white"
                    datatype="currency-data"
                    background="coin-background"
                    description="This is the average monthly payment for rent or mortgage repayments for a household."
                    user1={this.state.user1 ? this.state.user1.finance_monthly_rent_mortgage : false}
                    user2={this.state.user2 ? this.state.user2.finance_monthly_rent_mortgage : false}
                    tooltip_message="The proportion of people aged over 18 who are legal owners of the home in which they live fell from 57% in 2002 to 52% in 2014."
                />
                <BasicData
                    name="superannuation"
                    title="Superannuation balance"
                    color="white"
                    datatype="currency-data"
                    background="coin-background"
                    description="This is the average amount an individual like you reports to have in their super fund."
                    user1={this.state.user1 ? this.state.user1.finance_superannuation_balance : false}
                    user2={this.state.user2 ? this.state.user2.finance_superannuation_balance : false}
                    tooltip_message="In 2016 and 2017 (combined) 76% of retired men and almost 79% for retired women were receiving the Age Pension."
                />
                <BasicData
                    name="childcare"
                    title="$ spent on childcare"
                    color="white"
                    datatype="currency-data"
                    background="coin-background"
                    description="This reflects the median weekly expenditure on childcare (at December 2017 prices) for families using formal and/or nanny care."
                    user1={this.state.user1 ? this.state.user1.finance_weekly_childcare : false}
                    user2={this.state.user2 ? this.state.user2.finance_weekly_childcare : false}
                    tooltip_message="Weekly median childcare expenditure has increased significantly since 2002 —by around 136% ($65 in 2002/03 rising to $153 in 2016/17)."
                />
            </div>
        )
    }
}

export default FinanceResultTabContent