import React from "react"
import logosvg,  { ReactComponent as AuLogo} from '../../images/Illustrations/logo.svg'

const Logo = (props) => (
    <div className="home-content__top">
        <div className="home-content__top">
            <img src={logosvg} className="home-content__logo" alt="Austrlia & YOU"/>
            <h1 className="home-content__h1"><span className="striped-font" data-title={props.title}>{props.title}</span></h1>
        </div>
    </div>
);


export default Logo