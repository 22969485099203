import React, { Component } from "react";
import SaveUser from '../actions/save-user'
import DeleteUser from '../actions/delete-user'

const Option = ({ value, description }) => (
    <option value={value}>{description}</option>
);

const SelectBox = ({ children, onChange, value, name }) => (
    <select onChange={onChange} value={value} name={name}>
        {children}
    </select>
);

export default class UserFormMain extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.state = {
            foundID: false,
            age_group:false,
            sex:false,
            education:false,
            household_type:false,
            profile: false,
            description: false,
            results: false
        }
    }

    componentDidMount() {
        this.setState({ 
            age_group: this.props.age_group,
            sex: this.props.sex,
            education: this.props.education,
            household_type: this.props.household_type,
            profile: this.props.profile,
            description: this.props.description
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        const saved = SaveUser(event.target.id)
        if (saved && typeof window !== "undefined") { 
            // @TODO: instead of reloading the page, define the states
            window.location.reload()
        } else {
            this.setState({
                profile: 'No results',
                description: 'Please select other profile',
                results: !this.state.results
            })
        }
     
    }

    handleDelete = event => {
        event.preventDefault();
        console.log(event.target.id)
        const deleted = DeleteUser(event.target.id)
        if (deleted && typeof window !== "undefined") {
            // @TODO: instead of reloading the page, define the states
            window.location.reload()
        }
    }

    onAgeChange = e => { this.setState({ age_group: e.target.value });};
    onSexChange = e => { this.setState({ sex: e.target.value });};
    onEducationChange = e => { this.setState({ education: e.target.value });};
    onHouseholdChange = e => { this.setState({ household_type: e.target.value });};

    render() {
        return (
            <form onSubmit={this.handleSubmit} id={this.props.id} className="revealed" >
                <div className="typography-fields blue text-center">
                    <h3 className={"text-shadow fwbold white " +(this.state.results ? 'animated shake' : '')}>{this.state.profile}</h3>
                    <p>{this.state.description}</p>
                </div>
                <div>
                    <label>Age</label>
                    <div>
                        <SelectBox onChange={this.onAgeChange} value={this.state.age_group} name="age_group">
                            <Option value="15-24" description="15-24"/>
                            <Option value="25-34" description="25-34"/>
                            <Option value="35-44" description="35-44"/>
                            <Option value="45-54" description="45-54"/>
                            <Option value="55-64" description="55-64"/>
                            <Option value="65-74" description="65-74" />
                            <Option value="75+" description="75+"/>
                        </SelectBox>
                    </div>
                </div>
                <div>
                    <label>Sex</label>
                    <div>
                        <SelectBox onChange={this.onSexChange} value={this.state.sex} name="sex">
                            <Option value="M" description="Male" />
                            <Option value="F" description="Female" />
                            {/* <Option value="M" description="Non-binary" />
                            <Option value="F" description="Undisclosed" /> */}
                        </SelectBox>
                    </div>
                </div>
                <div>
                    <label>Level of Education</label>
                    <div>
                        <SelectBox onChange={this.onEducationChange} value={this.state.education} name="education">
                            <Option value="OPS" description="Post school diploma or Cert 3 or 4"/>
                            <Option value="Deg" description="Bachelor Degree, or or higher"/>
                            <Option value="CHS" description="Completed High School"/>
                            <Option value="NCHS" description="Did not complete High School"/>
                            <Option value="PG" description="Postgraduate Degree"/>
                        </SelectBox>
                    </div>
                </div>
                <div>
                    <label>Household</label>
                    <div>
                        <SelectBox onChange={this.onHouseholdChange} value={this.state.household_type} name="household_type">
                            <Option value="Coup" description="Couple" />
                            <Option value="Coupdep" description="Couple with dependent children" />
                            <Option value="Sparent" description="Single Parent with dependent children" />
                            <Option value="Other" description="Other" />
                        </SelectBox>
                    </div>
                </div>

                <div className="grid-x grid-margin-x">
                    <div className="cell medium-6"><button type="submit" className="button primary is-modal has-radius">Add Profile</button></div>
                    {this.props.id !== 'user-2' 
                        ? 
                        <div className="cell medium-6"><button type="reset" className="button primary is-modal has-radius">Reset</button></div>
                        :
                        <div className="cell medium-6"><button role="button" className="button primary is-modal has-radius" id={this.props.id} onClick={this.handleDelete}>Remove</button></div>
                    }
                </div>
            </form >
        )
    }
}
