import React, { Component } from "react";
import ToolTips from './tooltip'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor'
class BasicData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hide: false,
            prefix: false,
            user1: null,
            user2: null,
            element: null
        }
    }

   async componentDidMount() {
        
        if (this.props.user1 === "< 2" ||  this.props.user2 === "< 2") {
           await this.setState({ 
                prefix: !this.state.prefix,
                user1: this.props.user1 ? this.shifter(this.props.user1) : false,
                user2: this.props.user2 ? this.shifter(this.props.user2) : false,       
            })
            
        } else {
            this.setState({
                user1: this.props.user1,
                user2: this.props.user2
            })
        }
        
        if (!this.props.user1 && !this.props.user2) {
            this.setState({
                hide: !this.state.hide
            })
        }
    }

    

    shifter = (str) => {
        return str.split(" ").unshift();
        
    }

     onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ element: true});
        }

    }
   
    render() {
        var { element } = this.state;
        var { prefix } = this.state;

        return (
            
            <div className={"data-wrapper " + (this.props.name) + ' ' + (this.state.hide ? 'hide' : '')} data-aos="fade-up">
                <div className={"typography-fields " + (this.props.color)}>
                    <h3 className="text-shadow fwbold">{this.props.title}</h3>
                    <p>{this.props.description}</p>
                </div>
                <div className={"flex-column " + (this.props.background)}>
                    <div className={'user__data ' + (this.props.user2 !== undefined ? 'flex-responsive' : '')}>
                        
                        {!this.props.user1 ? null :                           
                            <section className="user__data--A">
                                <span className="profile">Profile A</span>
                                <span className={this.props.datatype}>
                                    {this.state.prefix ? '<' : ''}
                                    <VisibilitySensor onChange={this.onVisibilityChange}>
                                        <CountUp duration={1} delay={0} separator=","  end={this.state.element ? this.state.user1 : 0} />
                                    </VisibilitySensor>
                                </span>
                            </section>
                        }
                        {!this.props.user2 ? null :
                            <section className="user__data--B">
                                <span className="profile">Profile B</span>
                                <span className={this.props.datatype}>
                                    {this.state.prefix ? '<' : ''}
                                    <VisibilitySensor onChange={this.onVisibilityChange}>
                                        <CountUp duration={1} delay={5} separator="," end={this.state.element ? this.state.user2 : 0} />
                                    </VisibilitySensor>
                                </span>
                            </section>
                        }
                    </div>
                    <ToolTips color={this.props.color} message={this.props.tooltip_message} />
                </div>
                
            </div>
            
        )
    }
}


export default BasicData