
import React, { Component } from "react";
import Tooltip from 'react-tooltip-lite';
import sharesvg, { ReactComponent as Share } from "../../images/Icons/share.svg"
import fbvg, { ReactComponent as FB } from "../../images/Icons/facebook-f.svg"
import twittersvg, { ReactComponent as TW } from "../../images/Icons/twitter.svg"
import linkedinsvg, { ReactComponent as LK } from "../../images/Icons/linkedin.svg"
import emailsvg, { ReactComponent as EM } from "../../images/Icons/envelope.svg"

class ShareSocialMedia extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick = (url) => {
        if(window !== undefined) {
            window.open(url, '_blank', 'height=500, width=500');
        }
    }

    render() {
        return (
            <Tooltip
                content={(
                    <div className={"share-tooltip-content " + (this.props.color)}>
                        <ul className="social-links">
                            <li>
                                <a onClick={() => {this.handleClick( "https://www.facebook.com/sharer.php?u=" + ((typeof window !== 'undefined') ? window.location.origin : null) )}}  className="social-link">
                                    <span><img src={fbvg} alt="" /></span>
                                    <span>Facebook</span>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => {
                                    this.handleClick("https://twitter.com/intent/tweet?url=" 
                                        + ((typeof window !== "undefined") ? window.location.origin : null) 
                                        + "&text=Australia and YOU is a tool developed by the University of Melbourne using data from the Household Income and Labour Dynamic in Australia (HILDA) survey for you to explore how you compare with other Australians."
                                    )}}
                                   className="social-link"
                                >
                                    <span><img src={twittersvg} alt="" /></span>
                                    <span>Twitter</span>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => {
                                    this.handleClick("https://www.linkedin.com/shareArticle?mini=true&url=" + ((typeof window !== 'undefined') ? window.location.origin : null) + "&source=LinkedIn")
                                }}className="social-link">
                                    <span><img src={linkedinsvg} alt="" /></span>
                                    <span>Linkedin</span>
                                </a>
                            </li>
                            <li>
                                <a href={"mailto:?subject=HILDA&body=Hey, I think you might be interested in this." + ((typeof window !== "undefined") ? window.location.origin : null)} target="_top" className="social-link">
                                    <span><img src={emailsvg} alt="" /></span>
                                    <span>Email</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                )}
                tagName="span"
                arrow={false}
                direction="bottom"
                className={"share-tooltip target " + (this.props.color)}
                tipContentClassName=""
                tipContentHover
            >

                Share
                <span><img src={sharesvg} alt=""/></span>
                </Tooltip>
        );
    }
}

export default ShareSocialMedia