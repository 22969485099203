import { Link } from "gatsby"
import React, { Component } from "react"
import { LinkTo, animateScroll as scroll } from "react-scroll";
class Footer extends Component {
    constructor(props) {
        super(props);
    }

    handleScroll() {
        scroll.scrollToTop();
    }

    render() {
        return (
            <div className="footer">
                <div className="grid-container">
                    <ul className="menu vertical medium-horizontal">
                        <li><a onClick={this.handleScroll}>Compare your profile</a></li>
                        <li><a href="https://pursuit.unimelb.edu.au/business-economics" target="_blank">Business  Economics</a></li>
                        <li><Link to="/about">About Melbourne Institute</Link></li>
                        <li><Link to="/contact">Contact us</Link></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer
