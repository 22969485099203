import React,  {Component} from 'react'
import ResultsFinance from "./results-finance"
import ResultsEmployment from "./results-employment"
import ResultsLifestyle from "./results-lifestyle"
import ResultsFamily from "./results-family"
import ShareSocialMedia from '../other/share';

export default class ResultTabContents extends Component {
    render() {
        return (
            <section className="results-data">
                <div className="tabs-content" data-tabs-content="results-data-tabs">
                    <div className="tabs-panel is-active blue-bg arrow-background arrow-background--blue" id="finance-tab">
                        <div className="share-wrapper">
                            <ShareSocialMedia color="pink"/>
                        </div>
                        <ResultsFinance />
                    </div>
                    
                    <div className="tabs-panel secondary-bg arrow-background arrow-background--secondary" id="employment-tab">
                        <div className="share-wrapper">
                            <ShareSocialMedia color="blue"/>
                        </div>
                        <ResultsEmployment />
                    </div>

                    <div className="tabs-panel white-bg arrow-background arrow-background--white" id="lifestyle-tab">
                        <div className="share-wrapper">
                            <ShareSocialMedia color="pink" />
                        </div>
                        <ResultsLifestyle />
                    </div>

                    <div className="tabs-panel dark-blue-bg arrow-background arrow-background--dark-blue" id="family-tab">
                        <div className="share-wrapper">
                            <ShareSocialMedia color="pink" />
                        </div>
                        <ResultsFamily />
                    </div>
                    
                </div>
            </section>
        )
    }
}

