import React, { Component } from "react";
import UserSubmittedValues from "./user-submitted-value"
import ModalForm from "../form-types/form-modal"
import Modal from 'react-responsive-modal';
import GetUser from "../actions/get-user.js";
import Footer from "../footer";
class UserForm extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            user1: false,
            user2: false,
            open1: false,
            open2: false
        }
    }
    
    componentWillMount() {
        if (typeof window === 'undefined') {
            return;
        }

        const user_1 = GetUser('user-1');
        const user_2 = GetUser('user-2');
        
        this.setState({ 
            user1: user_1, 
            user2: user_2,
        });

    }
    handleOpenClose = (type, open) => this.setState({ [`open${type}`]: open });
    handleOpen1 = () => this.handleOpenClose('1', true);
    handleClose1 = () => this.handleOpenClose('1', false);
    handleOpen2 = () => this.handleOpenClose('2', true);
    handleClose2 = () => this.handleOpenClose('2', false);
    

    render() {
        const { open1 } = this.state
        const { open2 } = this.state
        const bg = {
            overlay: {background: 'rgb(18, 49, 140, 0.7)' },
            modal: {
                background: '#FFE1D8',
                width: 600,
            } 
        };
        
        return (
            <>
                {/* @TODO: Map it instead of creating new instances */}
                <UserSubmittedValues
                    id="user-1"
                    delay="delay-1s"
                    age_group={this.state.user1 ? this.state.user1.age_group : ''}
                    sex={this.state.user1 ? this.state.user1.sex : ''}
                    education={this.state.user1 ? this.state.user1.education : ''}
                    household_type={this.state.user1 ? this.state.user1.household_type : ''}
                    onClick={this.handleOpen1}
                    
                />

                <UserSubmittedValues
                    id="user-2"
                    delay="delay-2s"
                    none={(this.state.user2 !== undefined) ? false : true}
                    age_group={this.state.user2 ? this.state.user2.age_group : ''}
                    sex={this.state.user2 ? this.state.user2.sex : ''}
                    education={this.state.user2 ? this.state.user2.education : ''}
                    household_type={this.state.user2 ? this.state.user2.household_type : ''}
                    onClick={this.handleOpen2}
                />
                
                <Modal onClose={this.handleClose1} open={open1} styles={bg}>
                    <ModalForm
                        id="user-1"
                        profile="Edit my profile"
                        age_group={this.state.user1 ? this.state.user1.age_group : null}
                        sex={this.state.user1 ? this.state.user1.sex : null}
                        education={this.state.user1 ? this.state.user1.education : null}
                        household_type={this.state.user1 ? this.state.user1.household_type : null}
                    />
                </Modal>
                
                <Modal onClose={this.handleClose2} open={open2} styles={bg} modalId="user-form-2">
                    <ModalForm
                        id="user-2"
                        profile="Add another profile"
                        description="Select different characteristics to create another profile and find out how you compare to them."
                        age_group={ this.state.user2 ? this.state.user2.age_group : null}
                        sex={ this.state.user2 ? this.state.user2.sex : null}
                        education={ this.state.user2 ? this.state.user1.education : null}
                        household_type={ this.state.user2 ? this.state.user2.household_type : null}
                    />
                </Modal>
                
            </>
        )
    }
}

export default UserForm