import React, { Component } from "react"
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

class EmploymentTypeChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doughnutData: {
                labels: ["Full Time", "Part Time", "Casual", "Temp"],
                datasets: [
                    {
                        label: "Employment Type",
                        backgroundColor: ["#12318C", "#FFFFFF", "#A6B1CF", "#FFF7D7"],
                        data: this.props.user,
                        borderWidth: 0,
                        spanGaps: true,
                    }
                ]
            },

            doughnutOptions: {
                responsive: true,
                plugins: {
                    render: 'value',
                    fontFamily: "'interbold', 'Helvetica', 'Arial', sans-serif",
                    datalabels: {
                        anchor: 'start',
                        align: 'end',
                        formatter: function (value, context) {
                            return (value <= 2) ? '< ' + value + '%' : value + '%';
                        },
                        font: {
                            weight: 'bold',
                            size: 16
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        fontColor: "#12318C",
                        usePointStyle: true,
                        padding: 20,
                        fontFamily: "'interbold', 'Helvetica', 'Arial', sans-serif",
                    }
                }
            }
        }
    }

   
    render() {
        return (
            <div className="chart-container">
                <Doughnut
                    data={this.state.doughnutData}
                    options={this.state.doughnutOptions}
                />
            </div>
        )
    }
    
}

export default EmploymentTypeChart