

import React, { Component } from "react"
import { HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

class EmploymentOccupationChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            occupationData: {
                labels: ["Managers", "Professionals", "Technicians and Trades", "Community and Service", "Clerical and Administrative",
                    "Sales", "Machine Ops & Drivers", "Labourers"
                ],
                datasets: [
                    {
                        label: "Occupation",
                        backgroundColor: ["#12318C", "#FFFFFF", "#A6B1CF", "#FFF7D7", "#12318C", "#FFFFFF", "#A6B1CF", "#FFF7D7"],
                        data: this.props.user,
                        borderWidth: 0
                    }
                ]
            },
            barOptions: {
                legend: {
                    display: false,
                },
                plugins: {
                    render: false,
                    fontSize: 16,
                    datalabels: {
                        formatter: function (value, context) {
                            return (value <= 2) ? '< ' + value : value;
                        },
                        font: {
                            weight: 'bold',
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        display: true,
                        barPercentage: 0.5,
                        barThickness: 50,
                        maxBarThickness: 50,
                        minBarLength: 50,
                        gridLines: {
                            offsetGridLines: true
                        },
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 80,
                            stepSize: 25,
                            fontColor: '#12318C',
                            fontFamily: "'interbold', 'Helvetica', 'Arial', sans-serif",
                        },
                    }],
                    yAxes: [{
                        display: true,
                        gridLines: {
                            offsetGridLines: false,
                            color: 'transparent',
                        },
                        ticks: {
                            fontColor: '#12318C',
                            fontFamily: "'interbold', 'Helvetica', 'Arial', sans-serif",
                        }
                    }]
                }
            },
        }
    }

    render() {
        return (
            <div className="hchart-container">
                <HorizontalBar
                    data={this.state.occupationData}
                    options={this.state.barOptions}
                />
            </div>
        )
    }

}

export default EmploymentOccupationChart
