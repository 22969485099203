
import React, { Component } from "react"
import BasicData from '../other/basic-data'
import RoundChart from '../charts/round-chart'
import RelationshipStatus from "../charts/family-relationship-status"
import  ToolTips from "../other/tooltip.js";
// import HoursUnpaidWorkData from "../charts/share-work-comparison-data";
import GetUser from "../actions/get-user.js";

class FamilyResultTabContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user1:false,
            user2:false,
            relationshipStatusData1:false,
            relationshipStatusData2:false,
            // unpaidHoursWork1:false,
            // unpaidHoursWork2:false,
            size: 300,
            hide: false
        }
    }

    componentWillMount() {
        if (typeof window === 'undefined') {
            return;
        }
        const user_1 = GetUser('user-1');
        const user_2 = GetUser('user-2');
        this.setState({ 
            user1: user_1,
            user2: user_2,
            relationshipStatusData1: user_1 ? getRelationshipStatusData(user_1) : null,
            relationshipStatusData2: user_2 ? getRelationshipStatusData(user_2) : null,
            // unpaidHoursWork1: user_1 ? getUnPaidHoursData(user_1) : null,
            // unpaidHoursWork2: user_2 ? getUnPaidHoursData(user_2) : null
        });

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentDidMount() {
        // @TODO: Refarctor this! TEMP fix
        if(this.state.user1 && this.state.user2) {
            const bw_user1 = getBreadwinnerData(this.state.user1);
            const bw_user2 = getBreadwinnerData(this.state.user2);

            if(!bw_user1 && !bw_user2 ) {
                this.setState({ hide: !this.state.hide })
            }
        }

        if (this.state.user1 && !this.state.user2) {
            const bw_user1 = getBreadwinnerData(this.state.user1);
            if (!bw_user1) {
                this.setState({ hide: !this.state.hide })
            }
        }

    }

    resize() {
        if(window.innerWidth <= 760) {
            this.setState({ size: 100 })
        }
    }

    render() {
        return (
            <div className="grid-container">
                <div className="typography-fields white">
                    <h1 className="text-shadow fwbold">Family</h1>
                    <p>This section focuses on the dynamics of family and home life.</p>
                </div>

                <div className="data-wrapper relationship-status" data-aos="fade-up">
                    <div className="typography-fields white">
                        <h3 className="text-shadow fwbold">Relationship status</h3>
                        <p>The relationship status of individuals in the survey is defined as single, married, de facto, separated, divorced or widowed.</p>
                    </div>
                    <div className="flex-column">
                        <div className="user__data flex-responsive">
                            {(this.state.user1 === undefined) ? null :
                                <section className="user__data--A">
                                    <span className="profile">Profile A</span>
                                    <RelationshipStatus user={this.state.relationshipStatusData1} />
                                </section>
                            }
                            {(this.state.user2 === undefined) ? null :
                                <section className="user__data--B">
                                    <span className="profile">Profile B</span>
                                    <RelationshipStatus user={this.state.relationshipStatusData2} />
                                </section>
                            }
                        </div>
                        <ToolTips color="white" message="There has been a decline in the number of people who are legally married but a growing number of people in de facto relationships. In 2001, almost 24% of young adult men were married compared to 18% in 2017. At the same time the proportion on men in defacto relationships increased by 7 percentage points." />
                    </div>
                </div>
                
                <BasicData
                    name="been-divorced"
                    title="Ever been divorced"
                    color="white"
                    datatype="percentage-data white"
                    background="divorce-background"
                    description="This data refers to individuals who have ever been legally divorced. It does not capture people who are or have been separated or those who are in the process of getting divorced."
                    user1={this.state.user1 ? this.state.user1.family_ever_been_divorced : false}
                    user2={this.state.user2 ? this.state.user2.family_ever_been_divorced : false}
                    tooltip_message={false}
                />

                <div className={"data-wrapper the-breadwinner " + (this.state.hide ? 'hide' : '')} data-aos="fade-up">
                    <div className={"typography-fields white"}>
                        <h3 className="text-shadow fwbold">The breadwinner</h3>
                        <p>This data shows the breakdown of couples where the female is the main breadwinner and those in which the male is the main breadwinner.</p>
                    </div>
                    <div className="flex-column">
                        <div className={(this.state.user2 !== undefined) ? 'user__data flex-responsive' : 'user__data'}>
                            
                            {(this.state.user1 === undefined ) ? null :
                                (getBreadwinnerData(this.state.user1) == false ) ? null :
                                <section className="user__data--A">
                                    <span className="profile">Profile A</span>
                                    <RoundChart
                                        data_circle1={this.state.user1 ? this.state.user1.family_breadwinner_female : false}
                                        data_circle2={this.state.user1 ? this.state.user1.family_breadwinner_male : false}
                                        data_title2="Male"
                                        data_title1="Female"
                                        size={this.state.size}
                                    />
                                </section>
                            }

                            {(this.state.user2 === undefined ) ? null :
                                (getBreadwinnerData(this.state.user2) == false ) ? null :
                                <section className="user__data--B">
                                    <span className="profile">Profile B</span>
                                    <RoundChart
                                        data_circle1={this.state.user2 ? this.state.user2.family_breadwinner_female : false}
                                        data_circle2={this.state.user2 ? this.state.user2.family_breadwinner_male : false}
                                        data_title2="Male"
                                        data_title1="Female"
                                        size={this.state.size}
                                    />
                                </section>
                            }

                        </div>
                        <ToolTips color="white" message="The male breadwinner is still more predominant in Australian households but we are seeing a shift in regional areas where 24% of couples living in rural areas report a female breadwinner compared to 19% in urban areas." />
                    </div>
                </div>

                {/* <div className="data-wrapper share-of-work" data-aos="fade-up">
                    <div className="typography-fields white">
                        <h3 className="text-shadow fwbold">Share of work at home</h3>
                        <p>HILDA respondents report on the number of hours spent doing unpaid tasks in 7 categories ; i) housework and ii) care for children or relatives. The graph shows the combined average hours spent by men and women on these tasks based on the information you entered above. </p>
                    </div>
                    <div className="flex-column">
                        <div className="user__data flex-responsive">
                            {!this.state.user1 ? null :
                            <section className="user__data--A">
                                <span className="profile">Profile A</span>
                                    <HoursUnpaidWorkData
                                        self={getUnPaidHoursData(this.state.user1)[0]}
                                        couple={getUnPaidHoursData(this.state.user1)[1]}
                                        partner={getUnPaidHoursData(this.state.user1)[2]}
                                    />
                            </section>
                            }
                            {!this.state.user2 ? null :
                                <section className="user__data--B">
                                    <span className="profile">Profile B</span>
                                    <HoursUnpaidWorkData
                                        self={getUnPaidHoursData(this.state.user2)[0]}
                                        couple={getUnPaidHoursData(this.state.user2)[1]}
                                        partner={getUnPaidHoursData(this.state.user2)[2]}
                                    />
                                </section>
                            }
                        </div>
                        <ToolTips color="white" message="Men spend more time engaged in paid work and often report that they feel like they miss out on time at home and with their children. Women spend a disproportionate amount of time doing unpaid tasks which has significant implications for earnings, career progression and retirement savings. Research shows that by better sharing the load everyone would benefit." />
                    </div>
                </div> */}
            </div>
        )
    }
}

const getBreadwinnerData = (user) => {

    const data = [
        user.family_breadwinner_female,
        user.family_breadwinner_male
    ];

    return data.every(element => element === null) ? false : data;
}

const getRelationshipStatusData = (user) => {

    const data = [
        user.family_relatation_status_single,
        user.family_relatation_status_married,
        user.family_relatation_status_defacto,
        user.family_relatation_status_separated,
        user.family_relatation_status_divorced,
        user.family_relatation_status_widowed,
    ];

    return data.every(element => element === null) ? false : data;
}

const getUnPaidHoursData = (user) => {

    const data = [
        user.family_hours_unpaid_work_time_spent_by_self,
        user.family_hours_unpaid_work_time_spent_by_couples,
        user.family_hours_unpaid_work_time_spent_by_partner
    ];

    return data.every(element => element === null) ? false : data;
}


export default FamilyResultTabContent