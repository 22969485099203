import React, { Component } from "react"
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

class WeightTypeChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doughnutData: {
                labels: ["Healthy", "Overweight", "Underweight"],
                datasets: [
                    {
                        label: "Weight",
                        backgroundColor: ["#061D5C", "#12318C", "#CAD2E8"],
                        data: this.props.user,
                        borderWidth: 0,
                        spanGaps: true,
                    }
                ]
            },

            doughnutOptions: {
                plugins: {
                    render: 'value',
                    datalabels: {
                        anchor: 'start',
                        align: 'end',
                        color: '#fff',
                        formatter: function (value, context) {
                            return (value <= 2) ? '< ' + value + '%' : value + '%';
                        },
                        font: {
                            weight: 'bold',
                            size: 16,
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        fontColor: "#12318C",
                        usePointStyle: true,
                        fontFamily: "'interbold', 'Helvetica', 'Arial', sans-serif",
                        padding: 20,
                    }
                }
            }
        }
    }


    render() {
        return (
            <div className="chart-container">
                <Doughnut
                    data={this.state.doughnutData}
                    options={this.state.doughnutOptions}
                />
            </div>
        )
    }

}

export default WeightTypeChart