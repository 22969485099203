import React, { Component } from "react"
import financesvg, { ReactComponent as Finance } from "../../images/Icons/finance.svg"
import employmentsvg, { ReactComponent as Employment } from "../../images/Icons/employment.svg"
import lifestylesvg, { ReactComponent as Lifestyle } from "../../images/Icons/lifestyle.svg"
import familysvg, { ReactComponent as Family} from "../../images/Icons/family.svg"
import { Link, animateScroll as scroll } from "react-scroll";
import Sticky from 'react-sticky-el';
import AOS from 'aos';
export default class ResultTabs extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        activeKey: 0,
        items: [
            {
                name: 'Finance',
                svg: financesvg,
                comp: Finance,
            }, 
            {
                name: 'Employment',
                svg: employmentsvg,
                comp: Employment,
            }, 
            {
                name: 'Lifestyle',
                svg: lifestylesvg,
                comp: Lifestyle,
            },
            {
                name: 'Family',
                svg: familysvg,
                comp: Family,
            } 
        ],

    };
    
    // @TODO: Use ref from child components instead
    handleClick = (el, index) => {
        setTimeout(function() {AOS.refreshHard();}, 10);
        if(typeof window !== "undefined") {
            this.setState({ activeKey: index });
            this.state.items.map((item, index) => {
                let active_tab_content = window.document.getElementById(item.name.toLocaleLowerCase() + '-tab')
                let is_active = active_tab_content.classList.contains('is-active')
                if(is_active) { active_tab_content.classList.remove('is-active'); }
            }) 

            let tab_content = window.document.getElementById(el)
            let is_active = tab_content.classList.contains('is-active');
        
            if(!is_active) {
            tab_content.className += ' is-active'  
            }
        }
    }
    

    render() {
        return (
            <section className="results-tabs">
            <Sticky className="sticky-tabs">
                <div className="results-tabs__wrapper">
                    <ul className="tabs" id="results-data-tabs">
                        {this.state.items.map((item, index) =>

                            <li
                                key={index}
                                className={"tabs-title " + (this.state.activeKey === index ? 'is-active' : '')}
                                // onSelect={this.handleSelect.bind(this, index)}
                            >
                                <Link
                                    activeClass="is-active"
                                    to={`${item.name.toLocaleLowerCase()}-tab`}
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                    className="tabs-link"
                                    onClick={() => this.handleClick(`${item.name.toLocaleLowerCase()}-tab`, index)}
                                >
                               
                                    <img src={item.svg} alt={item.name}/>
                                    <span className="tabs-name">{item.name}</span>
                               
                                </Link>
                            </li>
                        )}
                    </ul>
                    
                </div>
            </Sticky>
            </section>

        );
    }
}