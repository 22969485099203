
import React from "react";
import Tooltip from 'react-tooltip-lite';

const BasicToolTips = (props) => {
    return (
        
        <Tooltip content={props.message} className={"tool-tip " + (props.color)} direction="down">
            {!props.message ? null :
            <button className={"tooltip-link " + (props.color)}>
                <span className={"link-learnmore--" + (props.color)}>&nbsp;</span>
                <span className={"link-learnmore " +(props.color)}>Did you know?</span>
            </button>
            }
        </Tooltip>
        
    );
}

export default BasicToolTips