import React, { Component } from 'react'

class RoundDataChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            circle1: this.props.data_circle1,
            circle2: this.props.data_circle2,
            size1: this.props.size,
            size2: this.props.size,
        }
    }

    componentDidMount() {
        // If circle 1 is less than circle 2, lessen the size of 1
        if (this.state.circle1 < this.state.circle2) {
            this.setState({size1: this.state.size1 - 50})
        }
    }

    getSize = (circle, size) => {
        let calc = `calc(${(circle !== null) ? +circle + 'px' : 0} + ${size}px)`;
        const sized = { width: calc, height: calc}
        return sized;
    }

    render() {
        
        const circle1 = this.getSize(this.state.circle1, this.state.size1);       
        const circle2 = this.getSize(this.state.circle2, this.state.size2);
        
        return (
            <div className="round-data light">
                {!this.state.circle1 ? null :
                <div className="round-data--A" data-aos="fade-right" style={circle1}>
                    <span className="percentage-data">{this.state.circle1}</span>
                    <span className="title-data">{this.props.data_title1}</span>
                </div>
                }
                {!this.state.circle2 ? null :
                    <div className="round-data--B" data-aos="fade-left" style={circle2}>
                    <span className="percentage-data">{this.state.circle2}</span>
                    <span className="title-data">{this.props.data_title2}</span>
                </div>
                }
            </div>
        )
    }
}

export default RoundDataChart