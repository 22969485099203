import 'chartjs-plugin-datalabels';
import React, { Component } from "react"
import EmploymentTypeChart from '../charts/employment-type'
import EmploymentOccupationChart from '../charts/employment-occupation'
import BasicData from '../other/basic-data';
import ToolTips from '../other/tooltip'
import GetUser from '../actions/get-user';
import 'chartjs-plugin-deferred';

class EmploymentResultTabContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user1:false,
            user2:false,
            employmentTpyeUser1:false,
            employmentTpyeUser2:false,
            employmentOccupationUser1:false,
            employmentOccupationUser2:false
        }
    }

    componentWillMount() {
        if (typeof window === 'undefined') {
            return;
        }
        const user_1 = GetUser('user-1');
        const user_2 = GetUser('user-2');
        this.setState({ 
            user1: user_1,
            user2: user_2,
            employmentTpyeUser1: user_1 ? getEmploymentTypeData(user_1) : null,
            employmentTpyeUser2: user_2 ? getEmploymentTypeData(user_2) : null,
            employmentOccupationUser1: user_1 ? getEmploymentOccupationData(user_1) : null,
            employmentOccupationUser2: user_2 ? getEmploymentOccupationData(user_2) : null,
        });
    }

    dividenOfTen(n) {
        if(n !== null || n !== 'undefined')
        return n / 10;
    }
    render() {
    
        return (

            <div className="grid-container">
                <div className="typography-fields dark-blue">
                    <h1 className="text-shadow white fwbold">Employment</h1>
                    <p>In each Survey, we ask participants about their labour market activity, such as type of employment, average hours and industry.</p>
                </div>
                
                <div className="data-wrapper employment-type">
                    <div className="typography-fields dark-blue">
                        <h3 className="text-shadow white fwbold">Type of Employment</h3>
                        <p>Using ABS categorisations of work, participants can select the following types of employment: employed, employed part-time, unemployed and not in the labour force. 71% of women and 82% of men are engaged in some form of employment. The following is broken down by type for someone with similar characteristics to you.</p>
                    </div> 
                    <div className="flex-column">
                        <div className={(this.state.user2 !== undefined) ? 'user__data flex-responsive' : 'user__data'}>
                            {!this.state.user1 ? null :
                                (this.state.employmentTpyeUser1 <= 0) ? null :
                                <section className="user__data--A">
                                    <span className="profile">Profile A</span>
                                    <EmploymentTypeChart user={this.state.employmentTpyeUser1} />
                                </section>
                            }
                            { !this.state.user2 ? null :
                                (this.state.employmentTpyeUser2 <= 0) ? null :
                                <section className="user__data--B">
                                    <span className="profile">Profile B</span>
                                    <EmploymentTypeChart user={this.state.employmentTpyeUser2} />
                                </section>
                            }
                        </div>
                        <ToolTips color="blue" message="From 2001 until 2008, unemployment participation had been rising and unemployment falling. Since the Global Financial Crisis, the labour market has been somewhat mixed, with the proportons of men and women employed remaining at or below their 2008 peaks." />
                    </div>
                </div>

                <div className="data-wrapper employment-occupation" data-aos="fade-up">
                    <div className="typography-fields dark-blue">
                        <h3 className="text-shadow white fwbold">Occupation</h3>
                        <p>Categorisations in the survey include: Manager; professional; technicians and trades workers; community and personal service workers; clerical and administrative workers; sales worker; machinery operators and drivers; labourers</p>
                    </div>
                    <div className="flex-column">
                        <div className={(this.state.user2 !== undefined) ? 'user__data flex-responsive' : 'user__data'}>
                            {!this.state.user1 ? null :
                                (this.state.employmentOccupationUser1 <= 0) ? null :
                                <section className="user__data--A">
                                    <span className="profile">Profile A</span>
                                    <EmploymentOccupationChart user={this.state.employmentOccupationUser1} />
                                </section>
                            }
                            {!this.state.user2 ? null :
                                (this.state.employmentOccupationUser2 <= 0) ? null :
                                <section className="user__data--B">
                                <span className="profile">Profile B</span>
                                    <EmploymentOccupationChart user={this.state.employmentOccupationUser2} />
                                </section>
                            }
                        </div>
                        <ToolTips color="blue" message="In 2001, 9% of employed females were managers and 25% were professionals. In 2017 these figures were 11% and 28%. For males, the corresponding figures were 16% and 21% in both 2001 and 2017." />
                    </div>
                </div>

                <BasicData
                    name="hours-worked"
                    title="Hours worked"
                    color="blue"
                    datatype="hours-data"
                    background="clock-background"
                    description="This number reflects the average hours worked by an individual in full time and part time employment based on the profile you entered above."
                    user1={this.state.user1 ? this.state.user1.employment_hours_worked : false}
                    user2={this.state.user2 ? this.state.user2.employment_hours_worked : false}
                    tooltip_message="Fathers with dependent children on average work the longest hours in paid employment."
                />
                
                <BasicData
                    name="commute"
                    title="Daily commute Time"
                    color="blue"
                    datatype="minute-data"
                    background="car-background"
                    description="The travel time between a person’s place of residence and place of work. HILDA also tracks working aged people with commute times of zero (i.e., those who work from home)."
                    user1={this.state.user1 ? this.state.user1.employment_daily_commute : false}
                    user2={this.state.user2 ? this.state.user2.employment_daily_commute : false}
                    tooltip_message="Australians in the major cities are now spending around ten minutes more travelling to work everyday than they were 15 years ago (from 55.2 in 2002 to 65.9 minutes per day in 2017)."
                />
               
                <div className="data-wrapper work-satisfaction" data-aos="fade-up">
                    <div className="typography-fields dark-blue">
                        <h3 className="text-shadow white fwbold">Job satisfaction</h3>
                        <p>Job satisfaction is measured on a scale from 0 (totally dissatisfied) to 10 (totally satisfied). The data displayed considers the mean in terms of satisfaction.</p>
                    </div>
                    <div className="slider-background flex-column">
                        <div className={"user__data slider-data " + ((this.state.user2 === undefined) ? '' : 'flex-responsive')}>
                            {!this.state.user1 ? null :
                                (getJobSatisfaction(this.state.user1) <= 0) ? null :
                                <section className="user__data--A">
                                    <span className="profile">Profile A</span>
                                    <div className="slider">
                                        <span style={{ left: `calc( ${this.dividenOfTen(this.state.user1 ? this.state.user1.employment_job_satisfaction : 0)} * 100%)` }} 
                                            className="slider-handle disabled" data-user={this.state.user1 ? this.state.user1.employment_job_satisfaction : 0}>

                                        </span>
                                    </div>
                                </section>
                            }
                            {!this.state.user2 ? null :
                                (getJobSatisfaction(this.state.user2) <= 0) ? null :
                                <section className="user__data--B">
                                    <span className="profile">Profile B</span>
                                    <div className="slider">
                                        <span style={{ left: `calc( ${this.dividenOfTen(this.state.user2 ? this.state.user2.employment_job_satisfaction : 0)} * 100%)` }} 
                                            className="slider-handle disabled" data-user={this.state.user2 ? this.state.user2.employment_job_satisfaction : 0}>

                                        </span>
                                    </div>
                                </section> 
                            }
                        </div>
                        <ToolTips color="blue" message="Overall job satisfaction also declined with commuting distance, from 7.8 points among the short-distance commuters to 7.4 points among the long-distance commuters." />
                        </div>
                    </div>
            </div>
            
        )
    }
}

const getJobSatisfaction = (user) => {
    const data =[
        user.employment_job_satisfaction
    ];
    return data.every(element => element === null) ? false : data;
}

const getEmploymentTypeData = (user) => {

    const data = [
        user.employment_type_full_time,
        user.employment_type_part_time,
        user.employment_type_casual,
        user.employment_type_labour_hire
    ];

    return data.every(element => element === null) ? false : data;
}

const getEmploymentOccupationData = (user) => {

    const data = [
        user.employment_occupation_manager,
        user.employment_occupation_professional,
        user.employment_occupation_technician_and_trades,
        user.employment_occupation_community_and_personal_service_worker,
        user.employment_occupation_clerical_and_administrative_work,
        user.employment_occupation_sales_worker,
        user.employment_occupation_machinery_operator_and_driver,
        user.employment_occupation_labourer
    ];

    return data.every(element => element === null) ? false : data;
 
    
}



export default EmploymentResultTabContent