import data from '../../data/hilda-final.json';

const SaveUser = ( user ) => {
    if (typeof window !== "undefined") {
        let id = window.document.getElementById(user)
        const form = new FormData(id);
        const formData = Array.from(form).reduce((o, [k, v]) => (o[k] = v, o), {});

        for (var i = 0; i < data.length; i++) {
            var found = true;
            for (var p in formData) {
                if (formData.hasOwnProperty(p)) {
                    if (data[i][p] !== formData[p]) {
                        found = false;
                        break;
                    }
                }
            }
            if (found) {
                window.sessionStorage.setItem(user, data[i].id); // Set user ID
                console.log('User has been saved with the id of ' + data[i].id);
                return true;
            }
        }

        return false;
    }
};

export default SaveUser