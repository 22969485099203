
import React, { Component } from "react"
import BasicData from '../other/basic-data'
import WeightTypeChart from "../charts/lifestyle-weight-chart.js";
import BasicToolTips from "../other/tooltip.js";
import GetUser from "../actions/get-user.js";

class LifestyleResultTabContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user1:false,
            user2:false,
            weightData1:false,
            weightData2:false,
            hide: false
        }
    }

    componentWillMount() {
        if (typeof window === 'undefined') {
            return;
          }
        const user_1 = GetUser('user-1');
        const user_2 = GetUser('user-2');

        this.setState({ 
            user1: user_1,
            user2: user_2,
            weightData1: user_1 !== undefined ? getWeightData(user_1) : false,
            weightData2: user_2 !== undefined ? getWeightData(user_2) : false,
        });
    }

    componentDidMount() {
        if (this.state.weightData1 <= 0 && this.state.weightData2 <= 0) {
            this.setState({ hide: !this.state.hide })
        }
    }

    toLocale = (data) => {
        if (data !== null) {
            return data.toLocaleString('AUD', { maximumFractionDigits: 2 });
        }
        return 'N/A';
    }

    render() {
        return (
            <div className="grid-container">
                <div className="typography-fields blue">
                    <h1 className="text-shadow fwbold">Lifestyle</h1>
                    <p>Here we focus on the factors that influence our individual well-being. The stats below are self-reported.</p>
                </div>

                <BasicData
                    name="alcohol-consumption"
                    title="Alcohol consumption"
                    color="blue"
                    datatype="glass-data"
                    background="beer-background"
                    description="Number of standard units of alcohol consumed by an individual on a weekly basis."
                    user1={this.state.user1 ? this.state.user1.lifestyle_alcoholic_drinks_per_week : false}
                    user2={this.state.user2 ? this.state.user2.lifestyle_alcoholic_drinks_per_week : false}
                    tooltip_message="Heavy drinkers (42 or more units per week) do not report increased concerns for their health unlike smokers who are much more likely to think their health will deteriorate in the next four years."
                />

                <BasicData
                    name="smoking"
                    title="Smoking"
                    color="blue"
                    datatype="percentage-data"
                    background="smoking-background"
                    description="The graph shows the percentage of smokers with similar characteristics to you."
                    user1={this.state.user1 ? this.state.user1.lifestyle_smokers : false}
                    user2={this.state.user2 ? this.state.user2.lifestyle_smokers : false}
                    tooltip_message="The overall proportion of people reporting being smokers has fallen from 22.3% in 2001 to 15.7% in 2017. Daily smokers have declined from 18.7% in 2002 (when we first measured this) to 12.3% in 2017."
                />

                <BasicData
                    name="ilicit-drugs"
                    title="Illicit drug use"
                    color="blue"
                    datatype="percentage-data"
                    background="drugs-background"
                    description="The term illicit drug use reflects two types of drug use assessed in the HILDA Survey: i) the use of illegal drugs and ii) the use of legal psychoactive substances in harmful ways (such as use of petrol or solvents as inhalants). The graph reflects anyone who has ever tried illicit drugs. "
                    user1={this.state.user1 ? this.state.user1.lifestyle_have_tried_ilicit_drugs : false}
                    user2={this.state.user2 ? this.state.user2.lifestyle_have_tried_ilicit_drugs : false}
                    tooltip_message="Over 90% of those who had used ilicit drugs had done so by 24. First use of ilicit drugs is very rare after the age of 40 years. "
                />
                
                <BasicData
                    name="gamble"
                    title="Gamble"
                    color="blue"
                    datatype="percentage-data"
                    background="cards-background"
                    description="40% of Australians report to gamble at least once a month. The graph shows the proportion of individuals who gamble once or more per week based on the profile you entered above."
                    user1={this.state.user1 ? this.state.user1.lifestyle_gamble_at_least_once_a_month : false}
                    user2={this.state.user2 ? this.state.user2.lifestyle_gamble_at_least_once_a_month : false}
                    tooltip_message="Men (43%) are more likely than women (35%) to gamble and gambling is and most prevalent among 45 and overs. The lotto is the most common form of gambling, with the pokies coming in third."
                />

                <div className={"data-wrapper weight " + (this.state.hide ? 'hide' : '')} data-aos="fade-up">
                    <div className="typography-fields blue">
                        <h3 className="text-shadow white fwbold">Overweight</h3>
                        <p>Average BMI is measured as weight in kilograms divided by height in metres squared. A healthy BMI is considered between 18.5 and 25. The graph shows the breakdown of people with similar characteristics to you who are underweight, healthy weight or overweight</p>
                    </div>
                    <div className="flex-column">
                        <div className={(this.state.user2 !== undefined) ? 'user__data flex-responsive' : 'user__data' }>
                            {(this.state.weightData1 >= 0) ? null :
                            <section className="user__data--A">
                                <span className="profile">Profile A</span>
                                <WeightTypeChart user={this.state.weightData1} />
                            </section>
                            }
                            {(this.state.weightData2 >= 0) ? null :
                                <section className="user__data--B">
                                    <span className="profile">Profile B</span>
                                    <WeightTypeChart user={this.state.weightData2} />
                                </section>
                            }
                        </div>
                        {/* <BasicToolTips color="blue" message=""/> */}
                    </div>
                </div>
                

                <BasicData
                    name="serious-illness"
                    title="Diagnosed with a serious illness"
                    color="blue"
                    datatype="percentage-data"
                    background="stethescope-background"
                    description="This data reflects the respondents who have been diagnosed with various serious illnesses which they currently still have and which have lasted, or are expected to last, six months or more. "
                    user1={this.state.user1 ? this.state.user1.lifestyle_diagnosed_with_serious_illness : false}
                    user2={this.state.user2 ? this.state.user2.lifestyle_diagnosed_with_serious_illness : false}
                    tooltip_message="There has been substantial increase in depression and anxiety across all age groups. Most notably in young people. In 2017, 20.1 per cent of women and 11.2 per cent of men in this age group reported being affected by these conditions compared to 12.8 per cent of women and 6.1 per cent of men in 2009."
                />
            </div>
        )
    }
}

const getWeightData = (user) => {
    const data = [
        user.lifestyle_weight_healthy_weight,
        user.lifestyle_weight_overweight,
        user.lifestyle_weight_underweight
    ];

    return data;
}

export default LifestyleResultTabContent